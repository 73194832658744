
  
  .label {
    width: 100%;
    font-size: 0.65rem;
    font-weight: 500;
    color:#fff;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }