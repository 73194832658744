.div_overlay {
  width: 1086px;
  height: 750px;
  left: 337px;
  top: 195px;
  background: #f3f3f3;
  border-radius: 5px;
}

.selected_button {
  background: #ececec;
  color: #00153f;
  margin-right: 3px;
  border: "0.5px solid #00153F";
}

.unselected_button {
  color: black;
}

.dividing_div {
  position: relative;
  width: 1041px;
  height: 0px;
  border-radius: 0.5px solid #000;
  top: 45px;
  left: 18px;
  border: 0.5px solid #000;
  opacity: 0.3;
}

.form_div {
  position: relative;
  left: 18px;
  top: 80px;
}

.project_title_input {
  width: 590px;
  height: 48px;
  background: rgba(0, 50, 160, 0.1);
  margin-top: 18px;
  border-radius: 5px;
  border: 1px solid #000;
  padding: 23.33px;
}

.project_type_input {
  width: 590px;
  height: 48px;
  background: rgba(0, 50, 160, 0.1);
  margin-top: 270px;
  border-radius: 5px;
  border: 1px solid #000;
  padding-left: 23.33px;
  padding-top: 0;
}

.slide_type_input {
  width: 590px;
  height: 48px;
  background: rgba(0, 50, 160, 0.1);
  margin-top: 330px;
  border-radius: 5px;
  border: 1px solid #000;
  padding-left: 23.33px;
  padding-top: 0;
}

.bottom_div {
  position: absolute;
  top: 700px;
}

.reset {
  width: 127px;
  height: 32px;
  border: 1px solid #0784e4;
  left: 16px;
  border-radius: 5px;
  font-size: 14px;
  color: #0784e4;
  font-weight: bold;
}

.savennext {
  left: 177px;
  background: #0784e4;
  border-radius: 5px;
  width: 127px;
  height: 32px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.div_separator {
  position: relative;
  width: 411px;
  height: 1px;
  border-radius: 0.5px solid #000;
  top: 57px;
  left: 18px;
  background: #2e519e;
}

.questions_div {
  /* position: absolute; */
  top: 197px;
  left: 20px;
  display: inline-flex;
  font-size: 14px;
  color: #2e519e;
}

.questionnaire_input {
  width: 450px;
  margin-left: 54px;
  position: absolute;
}
.right_div {
  width: 451px;
  height: 370px;
  left: 18px;
  position: relative;
}

@media all and (min-width: 2560px) {
  .div_overlay {
    left: 600px;
    width: 1000px;
    top: 300px;
    font-size: large;
    transform: scale(1.3);
    position: fixed;
  }
  .dividing_div {
    width: 850px;
  }
  .right_div {
    width: 651px;
    height: 570px;
    left: 1618px;
    top: 88px;
    position: relative;
  }
}
@media all and (min-width: 1921px) {
  .div_overlay {
    left: 450px;
    width: 1000px;
    top: 250px;
    font-size: large;
    transform: scale(1.3);
    position: fixed;
  }
  .dividing_div {
    width: 850px;
  }
  .right_div {
    width: 651px;
    height: 570px;
    left: 1618px;
    top: 84px;
    position: relative;
  }
  .project_list {
    transform: scale(1.3);
    position: fixed;
    top: 153px !important;
    width: 32em;
    margin-left: -5em;
  }
}
@media all and (max-width: 1920px) {
  .div_overlay {
    position: fixed;
    width: 1086px;
    height: 750px;
    left: 253px;
    top: 122px;
    background: #f3f3f3;
  }
  .right_div {
    width: 451px;
    height: 370px;
    left: 1238px;
    top: 92px;
    position: relative;
  }
  .project_list {
    position: fixed;
    top: 105px !important;
    width: 32em;
    margin-left: -5em;
  }
  .right_div {
    width: 451px;
    height: 370px;
    left: 1238px;
    top: 92px;
    position: relative;
  }
  /* .logout__btn {
    right: 100px !important;
  } */
}

@media all and (max-width: 1536px) {
  .div_overlay {
    transform: scale(0.85);
    left: 180px;
    top: 70px;
  }
  .project_list {
    transform: scale(0.9);
    position: fixed;
    top: 94px !important;
    width: 22em;
    margin-left: -17em;
  }
  .right_div {
    width: 451px;
    height: 370px;
    left: 1238px;
    top: 92px;
    position: relative;
  }
}

@media all and (max-width: 1440px) {
  .div_overlay {
    width: 800px;
    left: 220px;
    top: 75px;
    transform: scale(0.9);
  }

  .dividing_div {
    width: 760px;
  }

  .project_list {
    position: fixed;
    top: 80px !important;
    width: 26em !important;
    margin-left: 1.5em;
  }

  .bottom_div {
    position: absolute;
    top: 650px;
  }
  .right_div {
    width: 451px;
    height: 370px;
    left: 760px;
    top: 44px;
    /* position: relative; */
  }
}

@media all and (max-width: 1366px) {
  .div_overlay {
    width: 800px;
    height: 700px;
    position: fixed;
    top: 50px;
    left: 165px;
    transform: scale(0.8);
  }
  .right_div {
    width: 411px;
    height: 370px;
    left: 638px;
    top: 53px;
    position: relative;
  }

  .project_list {
    position: fixed;
    top: 90px !important;
    width: 26em !important;
    margin-left: 2em;
  }
}

@media all and (max-width: 1280px) {
  .div_overlay {
    width: 800px;
    height: 700px;
    position: fixed;
    top: 50px;
    left: 165px;
    transform: scale(0.8);
  }
  .right_div {
    width: 411px;
    height: 370px;
    left: 638px;
    top: 53px;
    position: relative;
  }
  .project_list {
    position: fixed;
    top: 90px !important;
    width: 25em !important;
    margin-left: 1.7em;
  }
}
