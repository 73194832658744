*{
    font-family: inter,Arial, Helvetica, sans-serif;
}
html{
    overflow: hidden;
}


* {
  --sb-track-color: #f8fbfd;
  --sb-thumb-color: #b9bfbc;
  --sb-size: 7px;

  scrollbar-color: var(--sb-thumb-color) 
                   var(--sb-track-color);
}

*::-webkit-scrollbar {
  width: var(--sb-size) ;
  height:var(--sb-size)
}

*::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 5px;
  
  }